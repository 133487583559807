import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

class StbUpload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isAuthenticated: false,
      password: "",
      isSubmitting: false,
      submitAllowed: false,
    }
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangePassword(event) {
    this.setState({ password: event.target.value }, () => { this.validateVerified(); });
  }

  validateVerified() {
    this.setState({
      submitAllowed: (this.state.password.trim().length > 0)
    });
  }

  async handleSubmit(event) {
    // disable 
    event.preventDefault();
    this.setState({ isSubmitting: true });
    await fetch('https://www.smarwag.de/api/v1/system/users-sessions', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password: this.state.password,
      })
    }).catch(error => console.log(error));
    this.setState({ isSubmitting: false });
  }

  render() {
    const isAuthenticated = this.state.isAuthenticated;
    let upload;
    if (isAuthenticated) { 
      upload = <h1>Upload</h1>;
    } else {
      upload = <div>
              <form onSubmit={this.handleSubmit}>
              <div className="field half">
              <label htmlFor="password">Passwort</label>
              <input id="password" type="password" value={this.state.password} onChange={this.handleChangePassword} />
            </div>
            <button type="submit" disabled={!this.state.submitAllowed} className="special">Upload entsperren</button>
      </form>
      </div>;
    }
    return (
      <Layout>
        <h1>Upload Bereich</h1>
        <p>Hallo Steuerberatungsteam Schilgen!<br /><br /></p>
          {upload}
        <Link to="/">Zurück zur Homepage</Link>
      </Layout>
    )
  }
}

export default StbUpload
